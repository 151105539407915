:root {
  --c-text: #111;
  --c-text-rgb: 17, 17, 17; /* #111 */
  --c-brand: #4fc87b;
  --c-brand-rgb: 79, 200, 123;
  --c-brand-hover: #36ae61;
  --c-container: #fff;
  --c-main-bg: #eeeff0;
  --c-grey_90: #dadada;
  --c-grey_96: #f5f5f5;
  --c-blue_98: #56aaf9;
  --c-error: #ee6f69;
  --c-error-rgb: 238, 111, 105;
  --c-skeleton-bg: rgba(var(--c-text-rgb), .05);

  /* Layouts colors */
  --c-header: #fff;
  --c-body: #eeeff0;

  /* Sizes */

  /* Text sizes */
  --s-text: 16px;
  --s-title: 28px;
  --s-text-title: 16px;

  /* Min Resolution 576 */
  @media (--min-small) {
    --s-title: 40px;
    --s-text-title: 18px;
  }

  /* Min Resolution 1200 */
  @media (--min-extra-large) {
    --s-title: 60px;
    --s-text-title: 24px;
  }
}
