.gdpr-tooltip {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1031;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.25);

  .gdpr-tooltip-container {
    max-width: 1110px;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .gdpr-image {
    width: 90px;
    height: 90px;
    margin: 0 0 16px;
    background-image: url(assets/burger.png);
    background-repeat: no-repeat;
  }

  .text-gdpr-tooltip {
    max-width: 338px;
    margin: 0 0 16px;
    font-size: 14px;
    line-height: 22px;
  }

  .gdpr-link {
    font-weight: 600;
    color: #111;
  }

  .wrapper-btns-gdpr-tooltip {
    width: 100%;
    max-width: 328px;
    display: flex;
    justify-content: space-between;
  }

  .btn-gdpr {
    width: 156px;
    height: 48px;
  }

  @media (min-width: 768px) {
    .gdpr-image {
      margin: 0 24px 0 0;
    }

    .gdpr-tooltip-container {
      width: 100%;
      padding: 24px 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .wrapper-btns-gdpr-tooltip {
      width: 212px;
      height: 112px;
      flex-direction: column;
    }

    .btn-gdpr {
      width: 212px;
    }

    .text-gdpr-tooltip {
      margin: 0 24px 0 0;
      font-size: 16px;
      line-height: 26px;
    }
  }

  @media (min-width: 1200px) {
    .gdpr-tooltip-container {
      max-width: 1440px;
    }

    .wrapper-btns-gdpr-tooltip {
      min-width: 440px;
      height: 48px;
      flex-direction: row;
    }

    .text-gdpr-tooltip {
      max-width: 532px;
      font-size: 16px;
      line-height: 26px;
    }
  }
}
