.auto-reload {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1050;
  padding: 1em;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: beige;
  opacity: .9;

  .button {
    font-weight: 600;
    color: #111;
  }
}
