html,
body {
  display: flex;
  flex-direction: column;
}

html {
  min-height: 100%;
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
div,
select,
input,
button,
textarea {
  padding: 0;
  margin: 0;
  font-size: var(--s-text);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

select,
input,
textarea {
  color: var(--c-text);
}

a {
  color: var(--c-brand);

  &:hover {
    color: var(--c-brand-hover);
    text-decoration: none;
  }
}

body {
  min-width: 360px;
  min-height: 100vh;
  color: var(--c-text);
  background: var(--c-main-bg);

  /* stylelint-disable */
  // check device

  &:before {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    content: 'mobile-small';
  }

  @media (min-width: 576px) {
    &:before {
      content: 'mobile';
    }
  }

  @media (min-width: 768px) {
    &:before {
      content: 'tablet';
    }
  }

  @media (min-width: 992px) {
    &:before {
      content: 'desktop';
    }
  }

  @media (min-width: 1200px) {
    &:before {
      content: 'desktop-full';
    }
  }

  /* stylelint-enable */
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: transparent;
}

/* stylelint-disable-next-line selector-max-id */
#outdated {
  padding: 0 40px 24px 10px;
  /* stylelint-disable-next-line selector-max-id */
  &:empty {
    display: none;
  }

  /* stylelint-disable-next-line selector-max-id */
  .last {
    right: 20px;
  }
}

.main-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
}

//Max Resolution 532
@media screen and (max-width: 532px) {
  /* stylelint-disable-next-line selector-max-id */
  #outdated h6 {
    font-size: 18px;
  }
}
