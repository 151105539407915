@import './variables';
@import './general';
@import './fonts';
@import './normalize'; // TODO: import from node_modules
@import '~outdated-browser-rework/style.scss';

.wrapper {
  width: 100%;
  height: inherit;
  margin: 0 auto;

  @media (min-width: 576px) {
    padding: 0 15px;
  }

  @media (min-width: 768px) {
    width: 720px;

    .subtitle {
      margin: 0 10px 32px;
      font-size: 28px;
    }
  }

  @media (min-width: 992px) {
    width: 960px;

    .subtitle {
      font-size: 36px;
    }
  }

  @media (min-width: 1200px) {
    width: 1140px;

    .subtitle {
      margin: 0 10px 52px;
      font-size: 46px;
    }
  }
}

.subtitle {
  margin: 0 10px 16px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
