@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url(../fonts/open-sans-300.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src:
    local('Open Sans'),
    local('OpenSans'),
    local('OpenSans-Regular'),
    url(../fonts/open-sans-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src:
    local('Open Sans Italic'),
    local('OpenSans-Italic'),
    url(../fonts/open-sans-italic.woff2) format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src:
    local('Open Sans Semibold'),
    local('OpenSans-Semibold'),
    url(../fonts/open-sans-600.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url(../fonts/open-sans-700.woff2) format('woff2');
}
